<script>
  import { scale, fade } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';

  let step = 1;
  const lastStep = 4;
  $: isLastStep = step === lastStep;

  let submit = false;
  let state = {
    name: "",
    email: "",
    message: "",
    phone: ""
  };

  function handleNext() {
    step += 1;
    if (step === 4 || isLastStep) {
      setTimeout(function() {
        if (window.__gMonitorForm) {
          const form = document.getElementById('cheddar-contact-wizard')
          if (form) {
            window.__gMonitorForm(form)  
          }
        }
      }, 500)
    }
  }

  function handleStepSubmit(e) {
    if (isLastStep) {
      submit = true;
    } else {
      e.preventDefault();
      handleNext();
    }
  }

  function getStepText(step) {
    switch (step) {
      case 1:
        return "Your Name";
      case 2:
        return "Your Email Address";
      case 3:
        return "Your Phone Number";
      case 4:
      default:
        return "Your Message";
    }
  }
</script>

<style>
  input,
  textarea {
    width: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
    border: 1px solid #fff;
    border-radius: 8px;
  }
  button {
    padding: 0.5rem;
    width: 100%;
    max-width: unset;
    margin: 0;
    white-space: nowrap;
  }
  .contact-button-wrapper {
    margin-top: 0.5rem;
  }
  .step {
    display: block;
    margin-bottom: 0.3rem;
    color: #fff;
  }
</style>

<label class="step">Step {step} of {lastStep}: {getStepText(step)}</label>
<form 
  on:submit={handleStepSubmit} 
  class:last-step={isLastStep} 
  method="post" 
  action="/process-form" 
  data-captcha-form
  id="cheddar-contact-wizard"
>
  {#if step === 1}
    <input type="text" 
    id="contact-us"
    placeholder="Full Name" bind:value={state.name} />
  {:else if step === 2}
    <input
      type="email"
      placeholder="Email Address"
      bind:value={state.email}
      autofocus />
  {:else if step === 3}
    <input
      type="text"
      placeholder="Phone Number"
      bind:value={state.phone}
      autofocus />
  {:else if isLastStep}
    <input type="hidden" name="form-id" value="20">
    <input type="hidden" name="your-name" bind:value={state.name} />
    <input type="hidden" name="email-address" bind:value={state.email} />
    <input type="hidden" name="phone-number" bind:value={state.phone} />
    <textarea
      name="message"
      placeholder="Message"
      bind:value={state.message}
      autofocus/>
  {/if}

  <div class="contact-button-wrapper">
    {#if isLastStep}
      <button type="submit" class="btn-like" data-captcha-trigger>Submit</button>
    {:else}
      <button type="submit" class="btn-like shadowless">Next ›</button>
    {/if}
  </div>

  {#if step <= 1}
    <!-- <div transition:scale="{{ duration: 300, opacity: 0.5, start: 0.5, easing: quintOut }}"> -->
    <div transition:fade="{{delay: 250, duration: 300}}">
      <div class="divider">or</div>
      <a href="https://cheesy.af/consult" class="btn-like shadowless">Book a Discovery Call</a>
    </div>
  {/if}
</form>

<!-- {#if submit}
  <div>
    <h2>Submitting to server:</h2>
    <pre>{JSON.stringify(state, null, 2)}</pre>
  </div>
{/if} -->
