import ContactForm from './App.svelte'

let mountNode

if (__DEV__) {
  console.log('dev mode')
  mountNode = document.body
} else {
  mountNode = document.getElementById('footer-contact-form')
  if (mountNode) {
    mountNode.innerHTML = ''
  }

  function focusForm(event) {
    event.preventDefault()

    var input = document.querySelector('.contact-block input')
    input.scrollIntoView()
    input.focus()
  }

  document
    .querySelectorAll('a[href="/contact"]:not(.social)')
    .forEach(function(node) {
      node.addEventListener('click', focusForm)
    })
}

if (mountNode) {
  const app = new ContactForm({
    target: mountNode
  })
}
